<template>
  <div class="container">
    <div class="section">
      <div class="columns is-multiline">
        <div class="column is-12 title-section">
          <h1>Demandantes</h1>
        </div>
        <div class="column is-12">
          <filters-component
            :getPostulantes="getPostulantes"
            :vacantes="vacantes"
          />
        </div>
        <div class="column is-12">
          <div class="card">
            <table class="table is-fullwidth is-narrow is-hoverable">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Correo electrónico</th>
                  <th>Teléfono</th>
                  <th>Vacante</th>
                  <th>Estatus</th>
                  <th>CV</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading">
                  <tr v-for="el in data" :key="el.id">
                    <td>
                      {{el.id}}
                    </td>
                    <td>
                      <router-link :to="`/postulante/detalle/${el.id}`">
                        {{el.name}}
                      </router-link>
                    </td>
                    <td>
                      {{el.email}}
                    </td>
                    <td>
                      {{el.phone}}
                    </td>
                    <td>
                      {{el.vacant_name}}
                    </td>
                    <td>
                      {{el.status === '1' ? 'Postulado' : null}}
                      {{el.status === '3' ? 'Rechazado' : null}}
                      {{el.status === '2' ? 'Aceptado' : null}}
                    </td>
                    <td>
                      <a @click="showPDF(el.cv)">
                        Descargar
                      </a>
                    </td>
                  </tr>
                </template>
                <tr v-if="data.length === 0 && !loading">
                  <td colspan="6" class="has-text-centered">
                    Sin información
                  </td>
                </tr>
                <tr v-if="loading">
                  <td colspan="6" class="has-text-centered is-loading">
                    <div class="lds-dual-ring" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <base-pagination
        class="is-medium"
        :page="page"
        :pages="parseInt(pages)"
        :action="paginator"
        :loading="loading"
      />
    </div>
    <modal-pdf
      v-if="modal"
      :close="openModalPdf"
      :url="file"
    />
  </div>
</template>

<script>
import api from '@/libs/api'
const RUTA_FILES = process.env.VUE_APP_RUTA_FILES
export default {
  name: 'Postulantes',
  components: {
    ModalPdf: () => import('@/components/postulantes/ModalPDF'),
    FiltersComponent: () => import('@/components/postulantes/filtros'),
    BasePagination: () => import('@/components/BasePagination')
  },
  data () {
    return {
      loading: false,
      loadingPDF: false,
      modal: false,
      file: null,
      data: [],
      vacantes: [],
      page: 1,
      pages: 51,
      filter: null
    }
  },
  methods: {
    async getPostulantes (page, filters = null) {
      this.filter = filters
      const response = await api.getPostulantes(page, filters)
      if (response.success) {
        this.data = response.payload
        this.pages = response.pages
      }
    },
    async paginator (page) {
      this.loading = true
      await this.getPostulantes(page, this.filter)
      this.loading = false
    },
    showPDF (cvFile) {
      this.file = `${RUTA_FILES}${cvFile}`
      console.log(this.file)
      this.openModalPdf()
    },
    openModalPdf () {
      this.modal = !this.modal
    }
  },
  mounted () {
    this.loading = true
    this.getPostulantes(1)
      .then(_ => {
        this.loading = false
      })
    api.getVacantes(1)
      .then(resp => {
        if (resp.success) {
          this.vacantes = resp.payload
        }
      })
  }
}
</script>

<style scoped>
</style>
